import Network from '@/services/network'

export default function () {
    const network = new Network;

    const fetchTypes = (query) => {
        return network.api('get', '/hr-payroll/attendance-policy/get-types' + query);
    }

    // ********************* Attendance-Policy-Start *****************
    const fetchAttendancePolicyList = (query) => {
        return network.api('get', '/hr-payroll/attendance-policy'+query);
    }

    const createAttendancePolicy = (data, query) => {
        return network.api('post', '/hr-payroll/attendance-policy'+query, data);
    }

    const fetchSingleAttendancePolicy = (policyId, query) => {
        return network.api('get', '/hr-payroll/attendance-policy/' + policyId + query);
    }

    const updateAttendancePolicy = (data, policyId, query) => {
        return network.api('put', '/hr-payroll/attendance-policy/'+ policyId + query, data);
    }
    // ********************* Attendance-Policy-End *****************

    // ********************* Attendance-Policy-Allocation-Start *****************
    const fetchAttendancePolicyAllocationList = (query) => {
        return network.api('get', '/hr-payroll/attendance-policy-allocation'+query);
    }

    const createAttendancePolicyAllocation = (data, query) => {
        return network.api('post', '/hr-payroll/attendance-policy-allocation'+query, data);
    }

    const fetchSingleAttendancePolicyAllocation = (allocationId, query) => {
        return network.api('get', '/hr-payroll/attendance-policy-allocation/' + allocationId + query);
    }

    const updateAttendancePolicyAllocation = (data, allocationId, query) => {
        return network.api('put', '/hr-payroll/attendance-policy-allocation/'+ allocationId + query, data);
    }
    // ********************* Attendance-Policy-Allocation-End *****************

    // ********************* Holiday-Start *****************
    const fetchHolidayList = (query) => {
        return network.api('get', '/hr-payroll/holidays'+query);
    }

    const createHoliday = (data, query) => {
        return network.api('post', '/hr-payroll/holidays'+query, data);
    }

    const fetchSingleHoliday = (holidayId, query) => {
        return network.api('get', '/hr-payroll/holidays/' + holidayId + query);
    }

    const updateHoliday = (data, holidayId, query) => {
        return network.api('put', '/hr-payroll/holidays/'+ holidayId + query, data);
    }
    // ********************* Holiday-End *****************

    // ********************* Attendance-Start *****************
    const fetchAttendanceList = (query) => {
        return network.api('get', '/hr-payroll/attendance'+query);
    }

    const createAttendance = (data, query) => {
        return network.api('post', '/hr-payroll/attendance'+query, data);
    }

    const fetchCurrentAttendanceInfo = (query) => {
        return network.api('get', '/hr-payroll/current-attendance' + query);
    }

    const updateAttendance = (data, attendanceId, query) => {
        return network.api('put', '/hr-payroll/attendance/'+ attendanceId + query, data);
    }
    // ********************* Attendance-End *****************

    // ********************* Leave-Policy-Start *****************
    const fetchLeavePolicyList = (query) => {
        return network.api('get', '/hr-payroll/leave-policy'+query);
    }

    const createLeavePolicy = (data, query) => {
        return network.api('post', '/hr-payroll/leave-policy'+query, data);
    }

    const fetchSingleLeavePolicy = (leavePolicyId, query) => {
        return network.api('get', '/hr-payroll/leave-policy/' + leavePolicyId + query);
    }

    const updateLeavePolicy = (data, leavePolicyId, query) => {
        return network.api('put', '/hr-payroll/leave-policy/'+ leavePolicyId + query, data);
    }
    // ********************* Leave-Policy-End *****************

    // ********************* Leave-Balance-Start *****************
    const fetchLeaveBalanceList = (query) => {
        return network.api('get', '/hr-payroll/leave-balance'+query);
    }

    const createLeaveBalance = (data, query) => {
        return network.api('post', '/hr-payroll/leave-balance'+query, data);
    }

    const fetchSingleLeaveBalance = (leaveBalanceId, query) => {
        return network.api('get', '/hr-payroll/leave-balance/' + leaveBalanceId + query);
    }

    const updateLeaveBalance = (data, leaveBalanceId, query) => {
        return network.api('put', '/hr-payroll/leave-balance/'+ leaveBalanceId + query, data);
    }
    // ********************* Leave-Balance-End *****************

    // ********************* Leave-Balance-Start *****************
    const fetchMyLeaveList = (query) => {
        return network.api('get', '/hr-payroll/my-leave'+query);
    }
    // ********************* Leave-Balance-End *****************

    // ********************* Leave-Approver-Start *****************
    const fetchLeaveApproverList = (query) => {
        return network.api('get', '/hr-payroll/leave-approver'+query);
    }
    const createLeaveApprover = (data, query) => {
        return network.api('post', '/hr-payroll/leave-approver'+query, data);
    }
    const fetchSingleLeaveApprover = (leaveApproverId, query) => {
        return network.api('get', '/hr-payroll/leave-approver/' + leaveApproverId + query);
    }
    const updateLeaveApprover = (data, leaveApproverId, query) => {
        return network.api('put', '/hr-payroll/leave-approver/'+ leaveApproverId + query, data);
    }
    // ********************* Leave-Approver-End *****************

    // ********************* Leave-Application-Approval-Start *****************
    const fetchLeaveApplicationApproval = (query) => {
        return network.api('get', '/hr-payroll/leave-application-approval'+query);
    }
    const createLeaveApplicationApproval = (data, query) => {
        return network.api('post', '/hr-payroll/leave-application-approval'+query, data);
    }
    const fetchSingleLeaveApplicationLog = (leaveApplicationLogId, query) => {
        return network.api('get', '/hr-payroll/leave-application-approval/' + leaveApplicationLogId + query);
    }
    const updateLeaveApplicationApproval = (data, leaveApplicationLogId, query) => {
        return network.api('put', '/hr-payroll/leave-application-approval/'+ leaveApplicationLogId + query, data);
    }
    // ********************* Leave-Application-Approval-Start *****************

    // ********************* Leave-Application-Start *****************
    const fetchLeaveApplication = (query) => {
        return network.api('get', '/hr-payroll/leave-application'+query);
    }
    const fetchSingleLeaveApplication = (leaveApplicationId, query) => {
        return network.api('get', '/hr-payroll/leave-application/' + leaveApplicationId + query);
    }
    const createLeaveApplication = (data, query) => {
        return network.api('post', '/hr-payroll/leave-application'+query, data);
    }
    const updateLeaveApplication = (data, leaveApplicationId, query) => {
        return network.api('put', '/hr-payroll/leave-application/'+ leaveApplicationId + query, data);
    }
    // ********************* Leave-Application-End *****************

    const fetchAttendanceLogReport = (query) => {
        return network.api('get', '/hr-payroll/attendance-log-summary'+query);
    }

    const fetchLeaveBalanceSummary = (query) => {
        return network.api('get', '/hr-payroll/leave-balance-summary'+query);
    }

    const fetchAttendanceSummaryReport = (query) => {
        return network.api('get', '/hr-payroll/attendance-summary'+query);
    }

    return {
        fetchTypes,
        createAttendancePolicy,
        fetchAttendancePolicyList,
        fetchSingleAttendancePolicy,
        updateAttendancePolicy,
        fetchAttendancePolicyAllocationList,
        fetchSingleAttendancePolicyAllocation,
        createAttendancePolicyAllocation,
        updateAttendancePolicyAllocation,
        fetchHolidayList,
        createHoliday,
        fetchSingleHoliday,
        updateHoliday,
        fetchAttendanceList,
        createAttendance,
        fetchCurrentAttendanceInfo,
        updateAttendance,
        fetchLeavePolicyList,
        createLeavePolicy,
        fetchSingleLeavePolicy,
        updateLeavePolicy,
        fetchLeaveBalanceList,
        createLeaveBalance,
        fetchSingleLeaveBalance,
        updateLeaveBalance,
        fetchMyLeaveList,
        fetchLeaveApproverList,
        createLeaveApprover,
        fetchSingleLeaveApprover,
        updateLeaveApprover,
        fetchLeaveApplicationApproval,
        fetchSingleLeaveApplication,
        createLeaveApplicationApproval,
        updateLeaveApplicationApproval,
        fetchLeaveApplication,
        createLeaveApplication,
        updateLeaveApplication,
        fetchSingleLeaveApplicationLog,
        fetchAttendanceLogReport,
        fetchLeaveBalanceSummary,
        fetchAttendanceSummaryReport
    }
}